import {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
} from "./icons"

export interface IGetHalva {
  icon: string
  title: string
}

export const ICONS: { [key: string]: () => JSX.Element } = {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
}

export const ITEMS: IGetHalva[] = [
  {
    icon: "CashBackIcon",
    title: "Кэшбэк <br/>до 10%",
  },
  {
    icon: "RemainedIcon",
    title: "До 17% <br/> на остаток",
  },
  {
    icon: "TransferIcon",
    title: "Бесплатные <br/>переводы",
  },
  {
    icon: "InstallmentIcon",
    title: "Рассрочка <br/>0%",
  },
  {
    icon: "ServiceIcon",
    title: "Обслуживание <br/>бесплатно",
  },
  {
    icon: "WithDrawingIcon",
    title: "Снятие наличных бесплатно",
  },
]

export const ITEMS_GET_HALVA: IGetHalva[] = [
  {
    icon: "CashBackIcon",
    title: "Кэшбэк <br/>до 10%",
  },
  {
    icon: "RemainedIcon",
    title: "До 17% <br/> на остаток",
  },
  {
    icon: "TransferIcon",
    title: "Бесплатные <br/>переводы",
  },
  {
    icon: "InstallmentIcon",
    title: "24 месяца <br/>рассрочки",
  },
  {
    icon: "ServiceIcon",
    title: "Обслуживание <br/>бесплатно",
  },
  {
    icon: "WithDrawingIcon",
    title: "Снятие наличных бесплатно",
  },
]

export const ITEMS_PROMOPAGES: IGetHalva[] = [
  {
    icon: "CashBackIcon",
    title: "Кэшбэк <br/>до 10%",
  },
  {
    icon: "RemainedIcon",
    title: "До 17% <br/> на остаток",
  },
  {
    icon: "TransferIcon",
    title: "Возвращаем <br/>500 рублей",
  },
  {
    icon: "InstallmentIcon",
    title: "Рассрочка <br/>0%",
  },
  {
    icon: "ServiceIcon",
    title: "Обслуживание <br/>бесплатно",
  },
  {
    icon: "WithDrawingIcon",
    title: "Снятие наличных бесплатно",
  },
]
